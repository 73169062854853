@import url(https://use.typekit.net/lwk1pej.css);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --container-padding: 10px;
  --anchor-font-size: min(2vw, max(2vh, 30px));

  /* Mode switch vars */
  --switch-width: 40px;
  --switch-height: calc(var(--switch-width) / 2);
  --switch-distance: calc(var(--switch-width) - var(--switch-height));

  /* Light mode colors */
  --light-1: rgba(240,240,240,1);
  --light-2: rgba(200,200,200,1);
  --light-3: rgba(160,160,160,1);
  --light-4: rgba(120,120,120,1);
  --light-5: rgba(60,60,60,1);
  --light-6: rgba(0,0,0,1);

  /* Dark mode colors */
  --dark-1: rgba(0,0,0,1);
  --dark-2: rgba(60,60,60,1);
  --dark-3: rgba(120,120,120,1);
  --dark-4: rgba(160,160,160,1);
  --dark-5: rgba(200,200,200,1);
  --dark-6: rgba(240,240,240,1);

  /* Style variables */
  --color-1: var(--light-1);
  --color-2: var(--light-2);
  --color-3: var(--light-3);
  --color-4: var(--light-4);
  --color-5: var(--light-5);
  --color-6: var(--light-6);

}


/*=============
MODE SWITCH
=============*/

#mode-switch{
  width: 40px;
  width: var(--switch-width);
  height:calc(40px / 2);
  height:var(--switch-height);
  background: rgba(0,0,0,1);
  background: var(--color-6);
  text-align: left;
  margin: 20px auto 20px;
  border-radius: calc(40px / 2);
  border-radius: var(--switch-height);
  border: solid rgba(0,0,0,1);
  border: solid var(--color-6);
  transition: all .2s;
}
#mode-switch span{
  display: inline-block;
  height: 100%;
  width: calc(40px / 2);
  width: var(--switch-height);
  background: rgba(120,120,120,1);
  background: var(--color-4);
  -webkit-animation: .5s lightmode forwards;
          animation: .5s lightmode forwards;
  border-radius: 100%;
}

.darkmode{
-webkit-animation: .5s darkmode !important;
        animation: .5s darkmode !important;
}

#mode-checkbox{
  display: none;
}

@-webkit-keyframes lightmode  {
  0% {
      -webkit-transform: translate( 0,0);
              transform: translate( 0,0);
  }
  100% {
      -webkit-transform: translate( calc(40px - calc(40px / 2)),0);
              transform: translate( calc(40px - calc(40px / 2)),0);
      -webkit-transform: translate( var(--switch-distance),0);
              transform: translate( var(--switch-distance),0);
  }
}

@keyframes lightmode  {
  0% {
      -webkit-transform: translate( 0,0);
              transform: translate( 0,0);
  }
  100% {
      -webkit-transform: translate( calc(40px - calc(40px / 2)),0);
              transform: translate( calc(40px - calc(40px / 2)),0);
      -webkit-transform: translate( var(--switch-distance),0);
              transform: translate( var(--switch-distance),0);
  }
}

@-webkit-keyframes darkmode  {
  0% {
      -webkit-transform: translate( calc(40px - calc(40px / 2)),0);
              transform: translate( calc(40px - calc(40px / 2)),0);
      -webkit-transform: translate( var(--switch-distance),0);
              transform: translate( var(--switch-distance),0);
  }
  100% {
      -webkit-transform: translate( 0,0);
              transform: translate( 0,0);
  }
}

@keyframes darkmode  {
  0% {
      -webkit-transform: translate( calc(40px - calc(40px / 2)),0);
              transform: translate( calc(40px - calc(40px / 2)),0);
      -webkit-transform: translate( var(--switch-distance),0);
              transform: translate( var(--switch-distance),0);
  }
  100% {
      -webkit-transform: translate( 0,0);
              transform: translate( 0,0);
  }
}


/* mode switch end */


.met-depts{
  margin: auto;
  width: 100%;
  height: 100vh;
  background: black;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  padding: 
          calc(10px * 3)
          calc(10px * 3);
  padding: 
          calc(var(--container-padding) * 3)
          calc(var(--container-padding) * 3);
  box-sizing: border-box;
  z-index:900;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align:left;
  transition: opacity .1s;
}

.met-depts h2{
  font-weight: 60000;
  font-size: calc(min(2vw, max(2vh, 30px)) * 1.7);
  font-size: calc(var(--anchor-font-size) * 1.7);
  color: #333;
}



.met-depts a{
  --anchor-font-size: min(1.4vw, 1rem);
  text-decoration: none;
  font-size: calc(min(2vw, max(2vh, 30px)) * 3);
  font-size: calc(var(--anchor-font-size) * 3);
  line-height: calc(min(2vw, max(2vh, 30px)) * 3.8);
  line-height: calc(var(--anchor-font-size) * 3.8);
  font-family: sybarite, serif;
  font-weight: 900;
  font-style: normal;
  color: #666;
  box-sizing: border-box;
}


.met-depts a:hover{
  color: white;
}

.color-square{
  z-index: 9000;
  position: fixed;
  top: -100px;
  left: 0;
  width: 50px;
  height: 50px;
  background: purple;
}




/* .met-depts a:after{
  content: "•";
  font-size: calc(var(--anchor-font-size) * 4);
  align-content: center;
  color: #333;
  padding: 0 .5rem;
  vertical-align: bottom;
}

.met-depts a:hover:after{
  color: #333;
}

.met-depts a:last-child:after{
  content: '';
} */

/*=============
  SYNTH CANVAS
 =============*/

 /* #synth-canvas{
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
 } */


/*==============
  ART CONTAINER
 ==============*/

.art-objects-container{
  grid-template-columns: 2;
  font-size: 1.2rem;
  line-height: 1.3rem;
  padding: 10% 5%;
}

.art-obj-card{
  display: inline-block !important;
}

.art-obj-info{
  padding: 10px 20px;
  box-sizing: border-box;
}

.art-obj-info ul{
  list-style: none;
  padding: 0;
}


@-webkit-keyframes flipAnime{
  0% {-webkit-transform: scaleX(1);transform: scaleX(1); }
  100% {-webkit-transform: scaleX(-1);transform: scaleX(-1); }
}


@keyframes flipAnime{
  0% {-webkit-transform: scaleX(1);transform: scaleX(1); }
  100% {-webkit-transform: scaleX(-1);transform: scaleX(-1); }
}

@-webkit-keyframes flopAnime{
  0% { -webkit-transform: scaleX(-1); transform: scaleX(-1); }
  100% { -webkit-transform: scaleX(1); transform: scaleX(1); }
}

@keyframes flopAnime{
  0% { -webkit-transform: scaleX(-1); transform: scaleX(-1); }
  100% { -webkit-transform: scaleX(1); transform: scaleX(1); }
}

.flip{
  -webkit-animation: flipAnime .25s ease-in-out;
          animation: flipAnime .25s ease-in-out; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards;
}
.flop{
  -webkit-animation: flopAnime .25s ease-in-out;
          animation: flopAnime .25s ease-in-out; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards;
}

@media (max-width: 600px){
  .met-depts a{
    font-weight: 600;
  }
  .met-depts{
      padding: 
              calc(10px * 3)
              calc(10px * 2);
      padding: 
              calc(var(--container-padding) * 3)
              calc(var(--container-padding) * 2);
      padding-top: 20%;
  }
  .met-depts a{
    --anchor-font-size: min(2vw);
  }
}

@media (min-width: 900px){
  .met-depts{
    padding: 
        calc(10px * 5)
        calc(10px * 8);
    padding: 
        calc(var(--container-padding) * 5)
        calc(var(--container-padding) * 8);

  }
}
@media (min-width: 1500px){
  .met-depts{
    padding: 
        calc(10px * 5)
        calc(10px * 17);
    padding: 
        calc(var(--container-padding) * 5)
        calc(var(--container-padding) * 17);

  }
}

/* 
Sybarite Small
font-family: sybarite, serif;
font-weight: 600;
font-style: normal;

Sybarite Small Italic
font-family: sybarite, serif;
font-weight: 600;
font-style: italic;

Sybarite Huge
font-family: sybarite, serif;
font-weight: 900;
font-style: normal;


Minion Pro Bold Cond Subhead
font-family: minion-pro-condensed-subhead, serif;
font-weight: 700;
font-style: normal;


Minion Pro Cond Italic Subhead
font-family: minion-pro-condensed-subhead, serif;
font-weight: 400;
font-style: italic;


Minion Pro Cond Subhead
font-family: minion-pro-condensed-subhead, serif;
font-weight: 400;
font-style: normal;


*/

html{
  font-family: minion-pro-condensed-subhead, serif;
}

.App {
  text-align: center;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family: sybarite, serif;
  font-weight: 900;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

header{
  width: 100%;
  display: grid;
  grid-template:"_ l d" auto / 20% 60% 20%;
  text-align: center;
}

header a{
  text-decoration: none;
  color: inherit;
  display: -webkit-inline-flex;
  display: inline-flex;
  grid-area: l;
  justify-self: center;
}

.dept-link:before{
  content: "•";
  font-size: 4rem;
  line-height:0;
  -webkit-align-content: center;
          align-content: center;
  color: #666;
  padding: 0 .5rem;
  vertical-align: top;
  display: inline-block;
  margin-top: 5px;
}

header .dept-link {
  grid-area: d;
  align-self: center;
  justify-self: center;
  color: #444;
  z-index:1000;
  cursor: pointer;
}

.hide{
  display: none;
}

main{
  margin: 0;
}

.intro{
  max-width: 80%;
  padding: 3% 0;
  margin: auto;
}
.intro h2{
  font-size: 3rem;
}
.intro p{
  font-size: 1.6rem;
  font-weight: 100;
}
.intro p b{
  font-size: 3rem;
  line-height: 0;
}
.intro h3{
  font-size: 1.8rem;
}
.intro button{
  font-size: 1.4rem;
  padding: 1% 5%;
}

.intro hr{
  opacity: 20%;
  margin:30px 20%;
}
.search-area {
  width: 80vw;
  margin: auto;
}

.search-area input {
  --search-font-size: 5vw;
  width: 80%;
  font-size: calc(var(--search-font-size) * .9);
  letter-spacing: calc(var(--search-font-size) * .005);
  font-family: minion-pro-condensed-subhead, serif;
  padding:  0.2rem 1.5rem;
  border-radius: 2.5vw;
  background: #EEE;
  outline: none;
  box-shadow: inset 0px 2px 0 #999;
  max-width: 700px;
  max-height: 70px;
}

/* DEPARTMENT DETAILS */

#link-icon {
  background-color: white; 
  border-radius: 100%; 
  height: 40px;
  width: 40px;
  margin-top: 30px;
  margin: 30px 5px 20px 5px
}

.art-obj-card{
  position: relative;
}

.art-obj-info{
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  background: black;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#sentinel{
  margin-bottom: 100px;
  font-size: 2rem;
}
#sentinel:after{
  font-size: 1.3rem;
  color: #666;
  text-align: center;
  display: block;
}


/* SYNESTHESIA */
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  background: rgba(0,0,0,.87);
  z-index:99999;
  color: #999;
  font-size: 2.3rem;
  padding: 5% 10%;
  box-sizing: border-box;
}
.modal p{
  display: block;
}
.modal button{
  font-size: 1.7rem;
  padding: 1% 5%;

}

/* 
SPINNER 
credit: https://tobiasahlin.com/spinkit/
*/

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 20px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #555;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

/* MEDIA QUERIES */

@media (max-width: 400px){
  header{
    grid-template: 
            "l" auto
            "d" 10px
            "space" 20px;
  }

}

@media (max-width:650px){

  .search-area input {
    font-size: calc(var(--search-font-size) * 1.3);
    letter-spacing: calc(var(--search-font-size) * .005);
  }
}

@media (max-width: 1000px){
  body{
    margin:0;
  }
  .art-objects-container{
    padding:0;
    width: 100%;
  }
  .art-obj-card{
    margin:0;
    padding:0;
  }
  .art-obj-card img{
    width: 100%;
  }
}

@media(min-width: 1000px){
  .search-area input {
    font-size: 45px;
    border-radius: 22px;
  }
}






